import $axios from 'axios'
import store from '../store/index'
import router from 'vue-router'
import { Message } from 'element-ui';
import { Loading } from 'element-ui';
const instance = $axios.create({
    baseURL: 'https://api.measure-biotech.com',
    timeout: 5000,
});


// loading函数
// 记录请求次数
let needLoadingRequestCount = 0;
let loading;
function startLoading() {
    loading = Loading.service({
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.5)',
    });
}
function endLoading() {
    // 延迟500ms，防止网速特快加载中画面一闪而过
    setTimeout(function () {
        if (loading) loading.close();
    }, 500);
}
// 打开loading
function showFullScreenLoading() {
    if (needLoadingRequestCount === 0) {
        startLoading();
    }
    needLoadingRequestCount++;
}
// 关闭loading
function tryHideFullScreenLoading() {
    if (needLoadingRequestCount <= 0) return;
    needLoadingRequestCount--;
    if (needLoadingRequestCount === 0) {
        endLoading();
    }
}

// Add a request interceptor
instance.interceptors.request.use(function (config) {
    // Do something before request is sent
    // 打开loading
    showFullScreenLoading();
    const token = store.getters.TOKEN
    if (token) config.headers.Authorization = 'Bearer ' + token
    // console.log(config.headers, '请求头');
    return config;
}, function (error) {
    // 关闭loading
    tryHideFullScreenLoading();

    // Do something with request error
    return Promise.reject(error);
});

// Add a response interceptor
instance.interceptors.response.use(function (response) {
    // 关闭loading
    tryHideFullScreenLoading();
    // Do something with response data
    // console.log(response, 'response');
    // response.data.code = 40001
    if (response.data.code == 40001) {
        // console.log(22);
        localStorage.removeItem('token')
        // MessageBox('登陆已过期，是否重新登录', '提示', {
        //     // confirmButtonText: '确定',
        //     // cancelButtonText: '取消',
        //     type: 'warning'
        //   }).then(() => {
        //     console.log(123);
        //     router.push('/login')
        //   }).catch(() => {

        //   });

    } else if (response.data.code == 0) {
        return response.data
    } else {
        return response.data
    }



    // 这里返回的response.data就是后台接口返回的数据
    // const res = response.data
    // // 如果返回的code不是200或201,则判断为错误
    // if (res.code && res.code !== 0) {
    //     // 分别对这些错误进行对应的处理
    //     if (res.code === 40001) {
    //         // 40001状态码表示token过期  清除过期token并返回登录页

    //         localStorage.removeItem('token')
    //         router.push('/login')
    //     }
    // }
    return response;
}, function (error) {
    // 关闭loading
    tryHideFullScreenLoading();
    // Do something with response error
    if (error.response.data.code == 40001) {
        console.log(22);
        localStorage.removeItem('token')
        localStorage.removeItem('userInfo')
        // Message.error('登录已过期')
    }
    // console.log(error.response, 'error');
    return Promise.reject(error);
});

const request = (options) => {
    options.method = options.method || 'get'
    if (options.method.toLocaleLowerCase() === 'get') {
        options.params = options.data || options.params
        delete options.data
    }
    return instance(options)
}
// let tokenLoad = false
// 重新获取token
// function reSetToken(params) {
//   // console.log('重新获取token：',params);
//   return new Promise(async (resolve, reject) => {
//     // 用函数形式将 resolve 存入，等待刷新后再执行
//     const app = getApp()
//     if (!app) {
//       console.error("app undefind");
//       return
//     }
//     wx.queue.asyncWait("login_back", () => {
//       service(params).then(res => {
//         resolve(res)
//       }, err => {
//         reject(err)
//       })
//     },params.url)

//     // true什么都不做，false走重新发起登录流程
//     if (tokenLoad) {
//       console.log("中断重新获取token");
//     } else {
//       // 将tokenLoad状态更改为true
//       tokenLoad = true
//       try {
//         // 重新发起登录
//         app.globalData.userInfo.Login().then(res => {
//           // console.log('发起登录',res);
//           if (res === 'notBindPhone') {
//             app.awaitLoginDialog('request')
//           }
//           tokenLoad = false  // 登录成功后将tokenLoad改为false，等待下次token过期重新发起登录
//         }).catch(() => {
//           tokenLoad = false
//         })
//       } catch (error) {
//         // 重新获取token报错，说明用户没有注册。需要唤起注册弹窗，如果是在tabbar页面，就不自动唤起
//         // console.log(error, '请求token失败报错')
//         app.awaitLoginDialog('request')
//         tokenLoad = false
//         reject(error)
//       }
//     }
//   })


// }

export default request