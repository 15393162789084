<template>
  <div class="home">
    <header>
      <div class="header">
        <!-- logo -->
        <div class="logo">
          <img src="@/assets/home_logo.png" alt="">
        </div>
        <!-- 栏目 -->
        <div class="navbar">
          <ul>
            <li v-for="item in navbarList" :key="item.id" :class="{ active: $route.path.includes(item.path) }"
              @click="handleNavTab(item.path)">{{ item.text }}</li>
          </ul>
        </div>
        <!-- 登录注册按钮 -->
        <div class="buttons">
          <button v-if="!$store.getters.TOKEN" class="logon-button" @click="$router.push('/logon')">注册</button>
          <button v-if="!$store.getters.TOKEN" class="login-button" @click="$router.push('/login')">登录</button>
          <el-dropdown @command="handleCommand">
            <div class="el-dropdown-link">
              <img v-if="$store.getters.TOKEN && avatar_url != ''" :src="avatar_url" alt="">
              <!-- <img v-if="$store.getters.TOKEN && avatar_url == ''"
                src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png" alt=""> -->
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div class="user" v-if="$store.getters.TOKEN">
            <div class="name">{{ name }}</div>
            <div class="school">{{ school }}</div>
          </div>
        </div>
      </div>
    </header>
    <!-- 中心内容 -->
    <div class="main">
      <router-view></router-view>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <!-- 版心 -->
      <div class="heart">
        <div class="navbar">
          <ul>
            <li v-for="item in navbarList" :key="item.id" @click="handleNavTab(item.path)">{{ item.text }}</li>
          </ul>
        </div>
        <div class="text">
          沪ICP备2023011351号-1 版权归茗修科技所有
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { handelUserIndex } from '@/api/user';
export default {
  name: 'home',
  data() {
    return {
      navbarList: [
        {
          id: 1,
          text: '首页',
          path: '/home'
        },
        {
          id: 2,
          text: '科艺“微”画',
          path: '/competition'
        },
        {
          id: 3,
          text: '产品展示',
          path: '/product-display'
        },
        {
          id: 4,
          text: '关于我们',
          path: '/about'
        }
      ],
      avatar_url: '',
      school: '',
      name: ''
    };
  },
  methods: {
    // 栏目跳转
    handleNavTab(path) {
      console.log(path);
      // if (this.$route.path.includes(path)) { return false }
      this.$router.push(path).catch(err => { })
    },
    // 个人中心
    async userIndexOk() {
      try {
        const res = await handelUserIndex()
        if (res.code == 0) {
          console.log(res, '个人中心');
          if (res.data.avatar_url == '') {
            this.avatar_url = 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
          } else {
            this.avatar_url = res.data.avatar_url
          }
          this.school = res.data.school
          this.name = res.data.name
          this.$store.commit('setUser', res.data)
        }
      } catch (error) {
        // if(error.response.status==401){
        //   this.$store.commit('removeToken')
        //   this.$router.push('/login')
        // }
        // this.$message.error('账号不存在')
      }
    },
    // 退出登录
    handleCommand(command) {
      if (command == 'a') {
        this.$store.commit('removeToken')
        this.$router.push('/login')
        this.$message.success('退出登录成功')
      }
    }
  },
  computed: {},
  created() {
    this.userIndexOk()

  },
  update() {
    // let user = this.$store.getters.USER
    // console.log(user.name);
    // if (user.avatar_url == '') {
    //   this.avatar_url = 'https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png'
    // } else {
    //   this.avatar_url = user.avatar_url
    // }
    // this.school = user.school
    // this.name = user.name
    console.log(update);
  },
  watch: {
    // avatar_url: {
    //   // 此处监听variable变量，当期有变化时执行
    //   handler(item1, item2) {
    //     // item1为新值，item2为旧值
    //     console.log(item1, '111');
    //     console.log(item2, '222');
    //   }
    // },
  },
}
</script>

<style lang="scss" scoped>
@media (max-width: 1500px) {
  .home {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-content: center;
    flex-direction: column;

    header {
      width: 100%;
      height: .9rem;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      margin-bottom: .08rem;

      .header {
        width: 13.2rem;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          display: flex;
          align-items: center;

          img {
            width: 1.76rem;
            height: .65rem;
          }
        }

        .navbar {
          height: 100%;

          ul {
            display: flex;
            list-style: none;
            height: 100%;

            li {
              width: 1.6rem;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: .18rem;
              font-family: PingFang SC;
              font-weight: 400;
              color: #365D62;
              cursor: pointer;
              border-radius: 3px;

              &.active {
                color: #FFFFFF;
                background: #20C0A8;
              }
            }
          }
        }

        .buttons {
          display: flex;
          align-items: center;

          .user {
            padding-left: .15rem;
            color: #20C0A8;

            .name {
              font-size: .16rem;
              margin-bottom: .1rem;
            }

            .school {
              font-size: .14rem;
            }

          }

          img {
            width: .65rem;
            height: .65rem;
            border-radius: 50%;
          }

          .logon-button {
            width: 1.4rem;
            height: .6rem;
            border: .02rem solid #20C0A8;
            border-radius: .14rem;
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #20C0A8;
            background: #FFFFFF;
            margin-right: .1rem;
            transform: scale(0.8);
          }

          .login-button {
            width: 1.4rem;
            height: .6rem;
            background: #20C0A8;
            border-radius: .14rem;
            border: 0;
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            transform: scale(0.8);
          }
        }
      }
    }

    // 中间内容
    .main {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .footer {
      width: 100%;
      height: 2.2rem;
      background: #333333;
      display: flex;
      justify-content: center;

      .heart {
        width: 13.2rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .navbar {
          width: 100%;
          display: flex;
          justify-content: center;
          padding-top: .69rem;
          padding-bottom: .48rem;
          border-bottom: .01rem solid #EEEEEE;

          ul {
            width: 8.34rem;
            display: flex;
            list-style: none;
            justify-content: space-between;

            li {
              font-size: .18rem;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              cursor: pointer;

            }
          }
        }

        .text {
          flex: 1;
          font-size: .18rem;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media (min-width: 1500px) {
  .home {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-content: center;
    flex-direction: column;

    header {
      width: 100%;
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      margin-bottom: 8px;

      .header {
        width: 1320px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .logo {
          display: flex;
          align-items: center;

          img {
            width: 176px;
            height: 65px;
          }
        }

        .navbar {
          height: 100%;

          ul {
            display: flex;
            list-style: none;
            height: 100%;

            li {
              width: 160px;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #365D62;
              cursor: pointer;
              border-radius: 3px;

              &.active {
                color: #FFFFFF;
                background: #20C0A8;
              }
            }
          }
        }

        .buttons {
          display: flex;
          align-items: center;

          img {
            width: 65px;
            height: 65px;
            border-radius: 50%;
          }

          .logon-button {
            width: 140px;
            height: 60px;
            border: 2px solid #20C0A8;
            border-radius: 14px;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #20C0A8;
            background: #FFFFFF;
            margin-right: 10px;
            transform: scale(0.8);
          }

          .login-button {
            width: 140px;
            height: 60px;
            background: #20C0A8;
            border-radius: 14px;
            border: 0;
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            transform: scale(0.8);
          }

          .user {
            padding-left: 15px;
            color: #20C0A8;

            .name {
              font-size: 16px;
              margin-bottom: 10px;
            }

            .school {
              font-size: 14px;
            }

          }
        }
      }
    }

    // 中间内容
    .main {
      width: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    .footer {
      width: 100%;
      height: 220px;
      background: #333333;
      display: flex;
      justify-content: center;

      .heart {
        width: 1320px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .navbar {
          width: 100%;
          display: flex;
          justify-content: center;
          padding-top: 69px;
          padding-bottom: 48px;
          border-bottom: 1px solid #EEEEEE;

          ul {
            width: 834px;
            display: flex;
            list-style: none;
            justify-content: space-between;

            li {
              font-size: 18px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #FFFFFF;
              cursor: pointer;

            }
          }
        }

        .text {
          flex: 1;
          font-size: 18px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #FFFFFF;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
