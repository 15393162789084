import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'homeView',
    component: HomeView,
    redirect: '/home',
    children: [
      // 首页
      {
        path: '/home',
        name: 'home',
        component: () => import('../views/home/index.vue')
      },
      // 关于我们
      {
        path: '/about',
        name: 'about',
        component: () => import('../views/about/index.vue')
      },
      //  产品展示
      {
        path: '/product-display',
        name: 'product-display',
        redirect: '/product-display/index',
        component: () => import('../views/product-display/product.vue'),
        children: [
          {
            path: '/product-display/index',
            name: 'product-display/index',
            component: () => import('../views/product-display/index.vue'),
            meta: {
              keepAlive: true
            },
          },
          {
            path: '/product-display/detail',
            name: 'product-display/detail',
            component: () => import('../views/product-display/product-detail.vue'),
          }
        ]
      },
      // 竞赛
      {
        path: '/competition',
        name: 'competition',
        redirect: '/competition/index',
        component: () => import('../views/competition/competition'),
        children: [
          // 竞赛首页
          {
            path: '/competition/index',
            name: 'competition-index',
            redirect: '/competition/index/competition-home',
            component: () => import('../views/competition/index/index.vue'),
            children: [
              {
                path: '/competition/index/competition-home',
                name: 'competition-home',
                component: () => import('../views/competition/index/competition-home.vue'),
              },
              // 常见问题
              {
                path: '/competition/index/competition-problem',
                name: 'competition-problem',
                component: () => import('../views/competition/index/problem.vue'),
              },
              // 讨论专区
              {
                path: '/competition/index/competition-discuss',
                name: 'competition-discuss',
                component: () => import('../views/competition/index/discuss.vue'),
              },
              // 帖子详情
              {
                path: '/competition/index/competition-discuss-detail',
                name: 'competition-discuss-detail',
                component: () => import('../views/competition/index/discuss-detail.vue'),
              },
              // 发帖
              {
                path: '/competition/index/competition-release',
                name: 'competition-release',
                component: () => import('../views/competition/index/release.vue'),
              },
              // 公告通知
              {
                path: '/competition/index/competition-notice',
                name: 'competition-notice',
                component: () => import('../views/competition/index/notice.vue'),
              },
              // 公告详情
              {
                path: '/competition/index/competition-notice-detail',
                name: 'competition-notice-detail',
                component: () => import('../views/competition/index/notice-detail.vue'),
              },
            ]
          },
          // 往届作品
          {
            path: '/competition/works',
            name: 'works',
            component: () => import('../views/competition/works/index.vue'),
          },
          // organization大赛组织
          {
            path: '/competition/organization',
            name: 'organization-index',
            redirect: '/competition/organization/organization-home',
            component: () => import('../views/competition/organization/index.vue'),
            children: [
              // 大赛组织
              {
                path: '/competition/organization/organization-home',
                name: 'organization-home',
                component: () => import('../views/competition/organization/organization.vue'),
              },
              // 大赛详情
              {
                path: '/competition/organization/organization-detail',
                name: 'organization-detail',
                component: () => import('../views/competition/organization/organization-detail.vue'),
              }
            ]
          },
          // 作品评比
          {
            path: '/competition/evaluation',
            name: 'evaluation-index',
            redirect: '/competition/evaluation/evaluation-home',
            component: () => import('../views/competition/evaluation/index.vue'),
            children: [
              // 作品评比
              {
                path: '/competition/evaluation/evaluation-home',
                name: 'evaluation-home',
                component: () => import('../views/competition/evaluation/evaluation.vue'),
                meta: {
                  keepAlive: true
                }
              },
              // 作品详情
              {
                path: '/competition/evaluation/evaluation-detail',
                name: 'evaluation-detail',
                component: () => import('../views/competition/evaluation/evaluation-detail.vue'),
              },
              // 往届作品详情
              {
                path: '/competition/works/detail',
                name: 'detail',
                component: () => import('../views/competition/works/detail.vue'),
              },
              {
                path: '/competition/evaluation/scoreed',
                name: 'scoreed',
                component: () => import('../views/competition/evaluation/scoreed.vue'),
              },
            ]
          },
          // 个人中心
          {
            path: '/competition/user',
            name: 'user-index',
            redirect: '/competition/user/user-home',
            component: () => import('../views/competition/user/user.vue'),
            children: [
              //个人信息
              {
                path: '/competition/user/user-home',
                name: 'user-home',
                component: () => import('../components/i-user.vue'),
              },
              //比赛报名
              {
                path: '/competition/user/user-competition',
                name: 'user-competition',
                component: () => import('../components/i-competition.vue'),
              },
              //报名显示
              {
                path: '/competition/user/user-display',
                name: 'user-display',
                component: () => import('../components/i-display.vue'),
              },
              // 帐户设置
              {
                path: '/competition/user/user-setup',
                name: 'user-setup',
                component: () => import('../components/i-setup.vue'),
              },
              // 投票评论
              {
                path: '/competition/user/user-comment',
                name: 'user-comment',
                component: () => import('../components/i-comment.vue'),
              },
              // 作品管理
              {
                path: '/competition/user/user-manage',
                name: 'user-manage',
                component: () => import('../components/i-manage.vue'),
              },
              // 团队信息
              {
                path: '/competition/user/user-team',
                name: 'user-team',
                component: () => import('../components/i-team.vue'),
              },
              // 文章公告
              {
                path: '/competition/user/user-article',
                name: 'user-article',
                redirect: '/competition/user/user-article/index',
                component: () => import('../components/article.vue'),
                children: [
                  {
                    path: '/competition/user/user-article/index',
                    name: 'user-article-index',
                    component: () => import('../components/i-article.vue'),
                  },
                  {
                    path: '/competition/user/user-article/edit',
                    name: 'user-article-edit',
                    component: () => import('../components/i-article-edit.vue'),
                  }
                ]
              },
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  // 登录
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/loginView/Login.vue')
  },
  // 注册
  {
    path: '/logon',
    name: 'logon',
    component: () => import('../views/loginView/Logon.vue')
  },
  // 忘记密码
  {
    path: '/forget',
    name: 'forget',
    component: () => import('../views/loginView/Forget.vue')
  },
]

const router = new VueRouter({
  routes
})
// 路由守卫
router.beforeEach((to, from, next) => {
  if (!localStorage.getItem('token')) {
    if (to.path.includes('/competition/user')) {
      next('/login')//守卫
      // setTimeout('console.clear()', 300)
    } else {
      next()
    }
  } else {
    next()
  }
})
export default router
