import request from "@/utils/request";

// 个人中心
export const handelUserIndex = (data) => {
    return request({
        url: '/api/user/index',
        method: 'post',
        data
    })
}
// 修改资料
export const handelUserEdit = (data) => {
    return request({
        url: '/api/user/edit',
        method: 'post',
        data
    })
}
// 上传图片
export const handelLocalUpload = (data) => {
    return request({
        url: '/api/localUpload',
        method: 'post',
        data
    })
}
// 文章分类/api/news/category
export const handelNewsCateGory = (data) => {
    return request({
        url: '/api/news/category',
        method: 'post',
        data
    })
}
// 比赛报名-活动列表
export const handelProjectList = (data) => {
    return request({
        url: '/api/project/list',
        method: 'post',
        data
    })
}
// 立即报名
export const handelTeamSign = (data) => {
    return request({
        url: '/api/team/sign',
        method: 'post',
        data
    })
}
// 报名显示/api/team/signList
export const handelTeamSignList = (data) => {
    return request({
        url: '/api/team/signList',
        method: 'post',
        data
    })
}
// 创建团队
export const handelTeamCreate = (data) => {
    return request({
        url: '/api/team/create',
        method: 'post',
        data
    })
}
// 搜索队员
export const handelTeamSearchMember = (data) => {
    return request({
        url: '/api/team/searchMember',
        method: 'post',
        data
    })
}
// 团队信息
export const handelTeamList = (data) => {
    return request({
        url: '/api/team/list',
        method: 'post',
        data
    })
}
// 通过团队编号查询团队信息
export const handelTeamDetail = (data) => {
    return request({
        url: '/api/team/detail',
        method: 'post',
        data
    })
}
// 加入团队
export const handelTeamJoin = (data) => {
    return request({
        url: '/api/team/join',
        method: 'post',
        data
    })
}
// 老师发表、修改文章
export const handelTeacherNewsCreate = (data) => {
    return request({
        url: '/api/teacher/newsCreate',
        method: 'post',
        data
    })
}
// 老师发表文章列表
export const handelTeacherNewsList = (data) => {
    return request({
        url: '/api/teacher/newsList',
        method: 'post',
        data
    })
}
// 作品评比-作品详情
export const handelUserWorksDetail = (data) => {
    return request({
        url: '/api/user/workDetail',
        method: 'post',
        data
    })
}
// 个人中心-投票评论列表
export const handelUserCommentList = (data) => {
    return request({
        url: '/api/team/commentList',
        method: 'post',
        data
    })
}
// 系统设置
export const handelSetting = (data) => {
    return request({
        url: '/api/setting',
        method: 'post',
        data
    })
}
// 竞赛首页-作品
export const handelUserIndexWord = (data) => {
    return request({
        url: '/api/user/indexWord',
        method: 'post',
        data
    })
}
// 老师评分列表
export const handelUserScoreList= (data) => {
    return request({
        url: '/api/user/scoreList',
        method: 'post',
        data
    })
}