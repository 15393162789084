import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '../public/flexible'

import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
  ak: "rQxHPQX1ua21rWnXzTbUh5KSSUbnefxR"
})
//引入饿了么组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

Vue.config.productionTip = false
// 跳转新页面后回到顶部
router.afterEach((to, from, next) => {
  window.scrollTo(0, 0);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
