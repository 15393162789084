import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import { setlotionToken, getlotionToken, remove, setlotionuser, getlotionuser } from '@/utils/auth'
export default new Vuex.Store({
  state: {
    token: '' || getlotionToken(),
    user: {} || getlotionuser(),
  },
  getters: {
    TOKEN(state) {
      return state.token
    },
    USER(state) {
      return getlotionuser()
    }
  },
  mutations: {
    setToken(state, data) {
      console.log(data.access_token);
      setlotionToken(data.access_token)
    },
    removeToken(state) {
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
    },
    setUser(state, data) {
      setlotionuser(data)
    },
    editUser(state,data){
      setlotionuser(data)
    }
  },
  actions: {
  },
  modules: {
  }
})
